// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-archiv-js": () => import("./../../../src/pages/archiv.js" /* webpackChunkName: "component---src-pages-archiv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-preistraeger-2021-js": () => import("./../../../src/pages/preistraeger-2021.js" /* webpackChunkName: "component---src-pages-preistraeger-2021-js" */),
  "component---src-pages-preistraeger-js": () => import("./../../../src/pages/preistraeger.js" /* webpackChunkName: "component---src-pages-preistraeger-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-verein-js": () => import("./../../../src/pages/verein.js" /* webpackChunkName: "component---src-pages-verein-js" */),
  "component---src-pages-wettbewerb-2024-js": () => import("./../../../src/pages/wettbewerb-2024.js" /* webpackChunkName: "component---src-pages-wettbewerb-2024-js" */)
}

